import {Component} from '@angular/core';
import {AlertService, StripeService, UserService, ValidationFormsService} from "@app/services";
import {Router} from "@angular/router";
import {AngularFireAuth} from "@angular/fire/compat/auth";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {initializeApp} from "@angular/fire/app";
import {environment} from "@environments/environment";
import {getAuth} from "@firebase/auth";

@Component({
  selector: 'app-payment-method',
  templateUrl: './payment-method.component.html',
  styleUrls: ['./payment-method.component.scss']
})
export class PaymentMethodComponent {
  public currentUser: any;
  checkPass: any;
  formChangeUsername!: UntypedFormGroup;
  submitted = false;
  formErrors: any;
  email: any;
  username: any;
  user: any;
  showUsername: boolean = true;
  showFormChangeUsername: boolean = false;
  listPaymentMethod: any;
  default_payment_method: string = '';
  visible: boolean = false;
  id: string = '';
  disable: boolean = false;

  constructor(
    private userService: UserService,
    private router: Router,
    public angularFireAuth: AngularFireAuth,
    private fb: UntypedFormBuilder,
    private alertService: AlertService,
    public vf: ValidationFormsService,
    private stripeService: StripeService
  ) {
    this.getCurrentUser().then();
  }

  ngOnInit() {
    this.formErrors = this.vf.errorMessages;
  }

  async getCurrentUser() {
    const app = await initializeApp(environment.firebaseConfig);
    const auth = await getAuth(app).currentUser;
    if (auth) {
      const user = await this.userService.getUserByMail(auth.email);
      this.user = user[0];
      if (user[0].stripe_cus == undefined) {

        this.listPaymentMethod = [];
      } else {
        const headers = {'content-type': 'application/json'}
        const body = {
          "customer": user[0].stripe_cus,
        };
        const urlListPaymentMethod = environment.list_payment_methods;

        this.stripeService.listPaymentMethod(urlListPaymentMethod, body, headers).toPromise().then((data: any) => {
          this.listPaymentMethod = data.data;
        });
        this.getCustomer(user[0].stripe_cus);
      }
    }
  }

  async getUserDb(email: any) {
    const user = await this.userService.getUserByMail(email);
    if (user && user[0]) {
      this.user = user[0];
    }
  }
  removePaymentMethod() {
    this.disable = true;
    try {
      this.alertService.clear()
      const urlRemovePaymentMethod = environment.remove_payment_methods;
      const headers = {'content-type': 'application/json'}
      const body = {
        "paymentMethodId": this.id,
      };

      this.stripeService.removePaymentMethod(urlRemovePaymentMethod, body, headers).toPromise().then((data: any) => {
        this.listPaymentMethod = this.listPaymentMethod.filter((data: any) => {
          return data.id != this.id;
        })
        this.visible = !this.visible;
        this.disable = false;
        this.alertService.success('カードを削除しました。');
      }).catch(() => {
        this.visible = !this.visible;
        this.disable = false;
        this.alertService.error('カードが削除されていません。');
      });
    } catch (e) {
      this.visible = !this.visible;
      this.disable = false;
      this.alertService.error('カードが削除されていません。');
    }
  }

  getCustomer(customerId: string) {
    const headers = {'content-type': 'application/json'}
    const urlCustomer = environment.customer_stripe + customerId;
    this.stripeService.getCustomer(urlCustomer, headers).toPromise().then((data: any) => {
      this.default_payment_method = data.customer.invoice_settings.default_payment_method
    })
  }

  setDefaultPaymentMethod(paymentMethodId: string) {
    try {
      this.alertService.clear()
      const headers = {'content-type': 'application/json'}
      const body = {
        "paymentMethodId": paymentMethodId,
        "customer": this.user.stripe_cus,
      };
      this.stripeService.setDefaultPaymentMethod(body, headers).toPromise().then((data: any) => {

        this.default_payment_method = paymentMethodId;
        this.alertService.success('クレジットカードをデフォルトにしました。');
      }).catch((e) => {
        this.alertService.error('クレジットカードはデフォルトに設定されていません。');
      });
    } catch (e) {
      this.alertService.error('クレジットカードはデフォルトに設定されていません。');
    }
  }

  handleChange(event: any) {
    this.visible = event;
  }

  toggle(id: string) {
    this.visible = !this.visible;
    this.id = id;
  }

  closeModal() {
    this.visible = !this.visible;
  }
}
