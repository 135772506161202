<c-container class="min-vh-90">
  <c-row class="justify-content-between mb-5">
    <c-col [md]="6" [lg]="6" [xs]="3">
      <img cImg src="assets/svg/main-logo.svg" alt="main logo" />
    </c-col>
    <c-col [md]="6" [lg]="6" [xs]="9">
      <app-noti></app-noti>
    </c-col>
  </c-row>

  <c-row class="mb-3 d-flex justify-content-center">
    <c-nav class="justify-content-center purchase-duration" variant="pills">
      <c-nav-item>
        <button [active]="true" cNavLink>毎月</button>
      </c-nav-item>
    </c-nav>
  </c-row>

  <c-row class="d-flex">
    <c-col [lg]="3" [md]="6" class=" my-3 purchase-plan" *ngFor="let price of prices ; index as i">
      <c-col class="p-3 " [class.purchase-free-plan]="price.type=='free'"
             [class.purchase-basic-plan]="price.type=='basic'"
             [class.purchase-standard-plan]="price.type=='standard'"
             [class.purchase-premium-plan]="price.type=='premium'">
        <p class="text-center">{{price.type|titlecase}}プラン</p>
        <c-col class="text-center">
          <img class="text-center" cImg src="assets/svg/line-spacing.svg" alt="line spacing" />
        </c-col>
        <h4 class="text-center">{{price.monthly_free}}</h4>
      </c-col>
      <c-col class="purchase-size">
        <c-col class="d-flex justify-content-between px-4">
          <p class="my-2 text-small">端末間で同期</p>
          <p class="my-2 text-small">{{price.sync_devices}}</p>
        </c-col>
        <c-col class="d-flex justify-content-between px-4">
          <p class="my-2 text-small">ノードサイズの条件</p>
          <p class="my-2 text-small">{{price.size_condition}}</p>
        </c-col>
        <c-col class="d-flex justify-content-between px-4">
          <p class="my-2 text-small">月間アップロード容量</p>
          <p class="my-2 text-small">{{price.upload_capacity_month}}</p>
        </c-col>
        <c-col class="d-flex justify-content-between px-4">
          <p class="my-2 text-small">常に広告なし</p>
          <p class="my-2 text-small">{{price.ad_free}}</p>
        </c-col>
      </c-col>
      <c-col *ngIf="(price.type!='free')">
        <button [disabled]="isDisableButton" *ngIf="price.type != accountType"  (click)="buyPlan(price.id,price.type)" cButton class="show-purchase-btn btn-orange" [ngClass]="isDisableButton ? 'd-none' : ''">
          購入
        </button>
      </c-col>

      <c-col *ngIf="price.type === accountType" class="mt-4 show-purchased-expiration">
        <p>契約日: {{ purchaseDate | date:'yyyy/MM/dd' }}</p>
        <p>次回更新日: {{ expirationDate }}</p>
        <c-col [xl]="4" [lg]="3" [md]="4" [sm]="4" [xs]="4" class="d-flex justify-content-end" *ngIf="price.type!='free'">
          <button  cButton class="btn-close-purchase-expiration" color="secondary" (click)="cancelPlan(price.type)"><span>キャンセル</span></button>
        </c-col>
      </c-col>
    </c-col>
  </c-row>
</c-container>
