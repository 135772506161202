import { Component, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { ClassToggleService, HeaderComponent } from '@coreui/angular';
import {UserService} from "@app/services";
import {Router} from "@angular/router";

@Component({
  selector: 'app-default-header',
  templateUrl: './default-header.component.html',
})
export class DefaultHeaderComponent extends HeaderComponent {

  @Input() sidebarId: string = "sidebar";

  public newMessages = new Array(4)
  public newTasks = new Array(5)
  public newNotifications = new Array(5)

  constructor(private classToggler: ClassToggleService, private userService: UserService, private router: Router) {
    super();
  }

  logout() {
    this.userService.SignOut().then(res => {
      this.router.navigate(['login']).then();
    })
      .catch(error => {
        console.log('Something is wrong:', error.message);
      });
  }
}
