import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from "@environments/environment";

@Injectable({
  providedIn: 'root'
})
export class StripeService {

  constructor(private db: AngularFirestore,
              private http: HttpClient
  ) {
  }

  // async create(Stripe: Stripe) {
  registerCard(baseUrl: string, body: any, headers: any) {
    return this.http.post(baseUrl, body, {'headers': headers});
  }
  stripeSubscription(baseUrl: string, body: any, headers: any) {
    return this.http.post(baseUrl, body, {'headers': headers});
  }
  delStripeSubscription(baseUrl: string, body: any, headers: any) {
    return this.http.post(baseUrl, body, {'headers': headers});
  }
  listPaymentMethod(baseUrl: string, body: any, headers: any) {
    return this.http.post(baseUrl, body, {'headers': headers});
  }
  createPaymentMethod(baseUrl: string, body: any, headers: any) {
    return this.http.post(baseUrl, body, {'headers': headers});
  }
  removePaymentMethod(baseUrl: string, body: any, headers: any) {
    return this.http.post(baseUrl, body, {'headers': headers});
  }
  getCustomer(baseUrl: string,headers: any) {
    return this.http.get(baseUrl,  {'headers': headers});
  }
  setDefaultPaymentMethod( body: any, headers: any) {
    const urlSetDefaultPaymentMethod = environment.set_default_payment_methods;
    return this.http.post(urlSetDefaultPaymentMethod,  body,{'headers': headers});
  }
}
