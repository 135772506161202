<c-container class="min-vh-90">
  <c-row class="justify-content-center">
    <c-col [md]="8" [lg]="5">
      <c-card-group>
        <c-card class="p-4 background-orange border-0">
          <c-card-body>
            <div  class="img-login-center">
              <img src="./assets/img/login/pana.png" alt="image logo">
            </div>
            <form [formGroup]="form" (ngSubmit)="Login()">
              <h1 class="text-center mb-4">ログイン</h1>
              <c-input-group class="mb-3">
                <span cInputGroupText>
                  <svg cIcon name="cilUser"></svg>
                </span>
                <input
                  [ngClass]="{ 'is-invalid': submitted && f['email'].errors }"
                  name="email"
                  formControlName="email"
                  autoComplete="email"
                  type="email"
                  cFormControl
                  required
                  placeholder="メールアドレス"/>
                <div *ngIf="submitted && f['email'].errors" class="invalid-feedback">
                  <div *ngIf="f['email'].errors['required']">正しい形式のメールアドレスをご入力ください。</div>
                </div>
              </c-input-group>
              <c-input-group class="mb-4">
                <span cInputGroupText>
                  <svg cIcon name="cilLockLocked"></svg>
                </span>
                <input
                  [ngClass]="{ 'is-invalid': submitted && f['password'].errors }"
                  name="password"
                  formControlName="password"
                  cFormControl
                  placeholder="パスワード"
                  required
                  type="password"
                />
                <div *ngIf="submitted && f['password'].errors" class="invalid-feedback">
                  <div *ngIf="f['password'].errors['required']">パスワードが無効です。</div>
                </div>
              </c-input-group>
              <c-row>
                <c-col class="text-right mb-3" [md]="12">
                  <a cButton class="px-0 text-orange btn-link" color="link" href="#/forgot-password">
                    パスワードを忘れた場合
                  </a>
                </c-col>
              </c-row>
              <c-row>
                <c-col [md]="12">
                  <button [disabled]="loading" cButton class="px-4 btn-orange width-100" type="submit">
                    <span>ログイン</span>
                  </button>
                </c-col>
              </c-row>
            </form>
            <br>
            <c-row>
              <c-col [md]="8" class="d-flex align-items-center">
                <p class="mb-0">ソーシャルログイン</p>
              </c-col>
              <c-col [md]="4">
                <div class="group-btn">
                  <button class="btn-social" (click)="SignInGoogle()">
                    <img src="./assets/img/login/login-google.png">
                  </button>
                  <button class="btn-social" (click)="SignInApple()">
                    <img src="./assets/img/login/login-apple.png">
                  </button>
                </div>
              </c-col>
            </c-row>
            <br>
            <c-row>
              <c-col [md]="12">
                <p class="text-center mb-0 line-center">
                  または
                </p>
              </c-col>
            </c-row>
            <br>
            <c-row>
              <c-col [md]="12">
                <p class="text-center mb-0">
                  まだアカウントを持っていない場合、<br>
                  <a class="text-orange btn-link" href="#/register">サインアップ</a>
                </p>
              </c-col>
            </c-row>
            <c-row>
              <c-col [md]="12">
                <p class="text-center mb-0">
                  <a class="text-orange btn-link" href="#/non-account">ログインせずに使用
                  </a>
                </p>
              </c-col>
            </c-row>
          </c-card-body>
        </c-card>
      </c-card-group>
    </c-col>
  </c-row>
</c-container>
