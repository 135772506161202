<c-container class="wrapper min-vh-90">
  <c-row class="justify-content-between mb-5">
    <c-col [md]="6" [lg]="6" [xs]="3">
      <img cImg src="assets/svg/main-logo.svg" alt="main logo" />
    </c-col>
    <c-col [md]="6" [lg]="6" [xs]="9">
      <app-noti></app-noti>
    </c-col>
  </c-row>
  <c-row class="justify-content-center">
    <c-col [md]="10" class="mb-4 d-flex justify-content-between align-items-center">
      <c-col>
        <h4 class="mb-0">通知</h4>
      </c-col>
      <c-col class="d-flex justify-content-end">
        <button class="btn-notification-action text-orange pointer-event" (click)="readMarkAll()">
          <img cImg src="assets/svg/mark-as-read-orange.svg" alt="mark as read"  />
          <strong class="mb-0 ms-2 text-orange pointer-event">すべて既読</strong>
        </button>
      </c-col>
    </c-col>
    <c-col [md]="10">
      <c-card>
        <c-card-body class="mx-5">
          <c-col *ngFor="let notification of notifications; index as i" class="list-notification">
            <c-col class="mb-3 d-flex ">
              <c-col [lg]="1" [md]="2" [sm]="2" [xs]="2">
                <c-col class="d-flex justify-content-end">
                  <img *ngIf="!notification.isRead" cImg class="me-3" src="assets/svg/red-dot.svg" alt="red dot"/>
                  <img cImg src="assets/svg/password-reset.svg" alt="password reset image" />
                </c-col>
              </c-col>
              <c-col [lg]="9" [md]="8" [sm]="8" [xs]="8" class="ps-3 flex-grow-1">
                <h5 class="mb-1 text-orange" [ngClass]="{ 'long-text' : !notification.isReadMore }">{{notification.messageTitle}}</h5>
                <p class="mb-0" [ngClass]="{ 'long-text' : !notification.isReadMore }">{{notification.messageBody}}</p>
              </c-col>
              <c-col [lg]="2" [md]="2" [sm]="2" [xs]="2" class="d-flex flex-grow-1">
                <c-col class="d-flex justify-content-end">
                  <p class="mb-0 text-orange ">{{notification.timeAgo}}</p>
                </c-col>
                <c-col class="notification-action d-none">
                  <c-dropdown direction="dropstart">
                    <button cDropdownToggle class="btn-notification-action">
                      <img cImg src="assets/svg/notification-more-grey.svg" alt="notification more" />
                    </button>
                    <ul cDropdownMenu>
                      <li>
                        <button cDropdownItem (click)="toggleReadMore(notification)">
                          <img cImg src="assets/svg/icon-note-black.svg" alt="read more" />
                          <span class="ms-2">{{ notification.isReadMore ? '少なく読む' : 'もっと見る' }}</span>
                        </button>
                      </li>
                      <li>
                      <li>
                        <button cDropdownItem (click)="readMark(notification.id)">
                          <img cImg src="assets/svg/mark-as-read-grey.svg" alt="mark as read" />
                          <span class="ms-2">既読にする</span>
                        </button>
                      </li>
                      <li>
                        <button cDropdownItem (click)="delete(notification.id)">
                          <img cImg src="assets/svg/trash.svg" alt="delete notification"   />
                          <span class="ms-2">通知を削除</span>
                        </button>
                      </li>
                    </ul>
                  </c-dropdown>
                </c-col>
              </c-col>
            </c-col>
          </c-col>

          <c-col *ngIf="!isOnePage" class="mt-4 d-flex justify-content-end">
            <button cButton class="btn-pagination me-3"
                    [disabled]="isDisablePreviousPage"
                    (click)="previousPage()"
                    [ngClass]="isDisablePreviousPage ? 'btn-secondary' : ''"
            >
              前へ
            </button>
            <button cButton class="btn-pagination"
                    (click)="nextPage()"
                    [disabled]="isDisableNextPage"
                    [ngClass]="isDisableNextPage ? 'btn-secondary' : ''"
            >
              次へ
            </button>
          </c-col>
        </c-card-body>
      </c-card>
    </c-col>
  </c-row>
</c-container>
