<c-container class="min-vh-90">
  <c-row class="d-flex justify-content-end pb-4 mx-0">
    <c-col [md]="2" [lg]="1" class="mx-0 px-0">
      <a href="#/login">
        <button cButton class="btn-redirect-login" type="submit">ログイン</button>
      </a>
    </c-col>
  </c-row>

  <c-row class="justify-content-center">
    <img cCardImg="top" loading="lazy" src="./assets/images/pana.svg" width="300px" height="300px" />
  </c-row>

  <c-row class="justify-content-center">
    <c-col [md]="8" [lg]="5">
      <c-card-group>
        <c-card-body class="p-4">
          <form cForm [formGroup]="formForgotPassword" novalidate (ngSubmit)="forgotPassword()" class="needs-validation">
            <h1 class="text-center">パスワードを忘れた方</h1>
            <br>
            <c-input-group class="mb-3">
              <span cInputGroupText>@</span>
              <input
                cFormControl
                id="email"
                type="email"
                formControlName="email"
                autocomplete="email"
                required
                placeholder="メールアドレス"
                [ngClass]="{ 'is-invalid': (submitted || f['email'].touched) && f['email'].invalid, 'is-valid': f['email'].touched && f['email'].valid  }"
              />
              <div *ngIf="submitted && f['email'].invalid" class="invalid-feedback">
                <div *ngIf=" f['email'].errors && f['email'].errors['required']">{{formErrors.email.required}}</div>
                <div *ngIf="f['email'].errors && f['email'].errors['email']">{{formErrors.email.email}}</div>
              </div>
            </c-input-group>
            <div class="d-grid">
              <button cButton class="px-4 btn-orange width-100"  type="submit">リセット</button>
            </div>
          </form>
        </c-card-body>
      </c-card-group>
    </c-col>
  </c-row>
</c-container>
