<c-container class="min-vh-90">
  <c-row class="justify-content-between mb-5">
    <c-col [md]="6" [lg]="6" [xs]="3">
      <img cImg src="assets/svg/main-logo.svg" alt="main logo" />
    </c-col>
    <c-col [md]="6" [lg]="6" [xs]="9">
      <app-noti></app-noti>
    </c-col>
  </c-row>

  <c-row class="d-flex justify-content-center">
    <c-col [md]="6">
      <c-card>
        <c-card-header>
          <h4>クレジットカードを追加</h4>
        </c-card-header>
        <c-card-body>
          <form cForm [formGroup]="formPayment" novalidate (ngSubmit)="addCardStripe()">
            <c-col class="mb-3">
              <label cLabel for="cardNumber">
                <strong>カード番号</strong>
              </label>
              <input cFormControl
                     autocomplete="cc-csc"
                     formControlName="cardNumber"
                     id="cardNumber"
                     name="cardNumber"
                     placeholder="4242 4242 4242 4242"
                     type="text"
                     (keypress)="numberOnly($event)"
                     class="card-input-number"
                     [ngClass]="{ 'is-invalid': (submitted || f['cardNumber'].touched) && f['cardNumber'].invalid, 'is-valid': f['cardNumber'].touched && f['cardNumber'].valid  }"
              />
              <div *ngIf="submitted && f['cardNumber'].invalid" class="invalid-feedback">
                <div *ngIf=" f['cardNumber'].errors && f['cardNumber'].errors['required']">{{formErrors.cardNumber.required}}</div>
              </div>
            </c-col>
            <c-col class="mb-3 d-flex justify-content-between">
              <c-col class="me-3">
                <label cLabel for="cardCVC">
                  <strong>CVC</strong>
                </label>
                <input cFormControl
                       autocomplete="cc-csc"
                       formControlName="cardCVC"
                       id="cardCVC"
                       name="cardCVC"
                       placeholder="123"
                       type="text"
                       (keypress)="numberOnly($event)"
                       class="card-input-number"
                       [ngClass]="{ 'is-invalid': (submitted || f['cardCVC'].touched) && f['cardCVC'].invalid, 'is-valid': f['cardCVC'].touched && f['cardCVC'].valid  }"

                />
                <div *ngIf="submitted && f['cardCVC'].invalid" class="invalid-feedback">
                  <div *ngIf=" f['cardCVC'].errors && f['cardCVC'].errors['required']">{{formErrors.cardNumber.required}}</div>
                </div>
              </c-col>
              <c-col>
                <label cLabel for="exp_month">
                  <strong>月</strong>
                </label>
                <input cFormControl
                       autocomplete="cc-csc"
                       formControlName="exp_month"
                       id="exp_month"
                       name="exp_month"
                       placeholder="MM"
                       type="text"
                       (keypress)="numberOnly($event)"
                       class="card-input-number"
                       [ngClass]="{ 'is-invalid': (submitted || f['exp_month'].touched) && f['exp_month'].invalid, 'is-valid': f['exp_month'].touched && f['exp_month'].valid  }"

                />
                <div *ngIf="submitted && f['exp_month'].invalid" class="invalid-feedback">
                  <div *ngIf=" f['exp_month'].errors && f['exp_month'].errors['required']">{{formErrors.exp_month.required}}</div>
                </div>
              </c-col>
              <c-col class="ms-3">
                <label cLabel for="exp_year">
                  <strong>年</strong>
                </label>
                <input cFormControl
                       autocomplete="cc-csc"
                       formControlName="exp_year"
                       id="exp_year"
                       name="exp_year"
                       placeholder="YYYY"
                       type="text"
                       (keypress)="numberOnly($event)"
                       class="card-input-number"
                       [ngClass]="{ 'is-invalid': (submitted || f['exp_year'].touched) && f['exp_year'].invalid, 'is-valid': f['exp_year'].touched && f['exp_year'].valid  }"

                />
                <div *ngIf="submitted && f['exp_year'].invalid" class="invalid-feedback">
                  <div *ngIf=" f['exp_year'].errors && f['exp_year'].errors['required']">{{formErrors.exp_year.required}}</div>
                </div>
              </c-col>
            </c-col>
            <c-col>
              <button cButton class="btn-orange" type="submit" [disabled]="!btnRegister">クレジットカードを追加</button>
            </c-col>
          </form>
        </c-card-body>
      </c-card>
    </c-col>
  </c-row>
</c-container>
