import {Component, OnInit} from '@angular/core';
import {ValidationFormsService} from "../../../services/validate-form.service";
import {ValidatorFn, ValidationErrors, UntypedFormGroup, UntypedFormBuilder, Validators} from '@angular/forms';
import {UserService, AlertService, StripeService} from '@app/services';
import {Router} from "@angular/router";
import {AngularFireAuth} from "@angular/fire/compat/auth";
import {environment} from "@environments/environment";

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {
  formPayment!: UntypedFormGroup;
  submitted = false;
  formErrors: any;
  mail: any;
  customer: any;
  user: any;
  baseUrl: string = environment.register_url_stripe;
  btnRegister: boolean = true;

  ngOnInit(): void {
    this.getCurrentUser().then()
  }

  constructor(private userService: UserService,
              private router: Router,
              private fb: UntypedFormBuilder,
              public vf: ValidationFormsService,
              private AlertService: AlertService,
              public angularFireAuth: AngularFireAuth,
              public stripeService: StripeService,
  ) {

    this.formErrors = this.vf.errorMessages;
    this.createForm();

  }

  createForm() {
    this.formPayment = this.fb.group(
      {
        cardNumber: ['', [Validators.required]],
        exp_month: ['', [Validators.required]],
        exp_year: ['', [Validators.required]],
        cardCVC: ['', [Validators.required]],
      },
    );
  }

  get f() {
    return this.formPayment.controls;
  }

  onValidate() {
    this.submitted = true;
    return this.formPayment.status === 'VALID';
  }


  async addCardStripe() {

    this.AlertService.clear();
    if (this.onValidate()) {
      this.btnRegister = false;
      try {
        const headers = {'content-type': 'application/json'}

        if(!this.user.stripe_cus){

          const body = {
            email: this.mail,
            number: this.formPayment.value.cardNumber,
            exp_month: this.formPayment.value.exp_month,
            exp_year: this.formPayment.value.exp_year,
            cvc: this.formPayment.value.cardCVC
          };
          // todo register
          await this.registerCard(body, headers);
          await this.userService.update(this.customer, this.mail);
          this.router.navigate(['/payment-method']).then(res => {
            this.btnRegister =true;
            this.AlertService.success('クレジットカードを追加しました。');
          });
        }else {
          // add payment method
          const body = {
            customer: this.user.stripe_cus,
            number: this.formPayment.value.cardNumber,
            exp_month: this.formPayment.value.exp_month,
            exp_year: this.formPayment.value.exp_year,
            cvc: this.formPayment.value.cardCVC
          };
           await this.addCard(body, headers)
           this.router.navigate(['/payment-method']).then(res => {
             this.btnRegister =true;
             this.AlertService.success('クレジットカードを追加しました。');
           });
         }
      } catch (e) {
        this.btnRegister = true;
        this.AlertService.error('クレジットカードは追加できません。');
      }
    }
  }

  async getCurrentUser() {
    const currentUser = await this.angularFireAuth.currentUser;
    if (currentUser) {
      this.mail = currentUser.email;
      const user = await this.userService.getUserByMail(this.mail);
      if (user && user[0]) {
        this.user = user[0];
      }
    }
  }

  async registerCard(body: any, headers: any) {
    const card = await this.stripeService.registerCard(this.baseUrl, body, headers).toPromise().then((data: any) => {
      this.customer = data.card.customer;
    });
  }
  async addCard(body: any, headers: any) {
    const add_payment_methods = environment.add_payment_methods;
     await this.stripeService.createPaymentMethod(add_payment_methods, body, headers).toPromise().then((data: any) => {

    });
  }
  numberOnly(event:any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
}
