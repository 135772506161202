<c-container class="min-vh-90">
  <c-row class="justify-content-center">
    <c-col md="6">
      <div class="clearfix">
        <h1 class="float-start display-3 me-4">404</h1>
        <h4 class="pt-3">Oops! You're lost.</h4>
        <p class="text-medium-emphasis float-start">
          The page you are looking for was not found.
        </p>
      </div>
      <c-input-group class="input-prepend">
        <span cInputGroupText>
          <svg cIcon name="cilMagnifyingGlass"></svg>
        </span>
        <input cFormControl placeholder="What are you looking for?" type="text" />
        <button cButton color="info">Search</button>
      </c-input-group>
    </c-col>
  </c-row>
</c-container>
