import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-non-account',
  templateUrl: './non-account.component.html',
  styleUrls: ['./non-account.component.scss']
})
export class NonAccountComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
