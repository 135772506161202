<c-container class="min-vh-90">
  <c-row class="justify-content-between mb-5">
    <c-col [md]="6" [lg]="6" [xs]="3">
      <img cImg src="assets/svg/main-logo.svg" alt="main logo" />
    </c-col>
    <c-col [md]="6" [lg]="6" [xs]="9">
      <app-noti></app-noti>
    </c-col>
  </c-row>

  <c-row class="justify-content-center mb-4">
    <c-col [md]="10">
      <h3>アカウント</h3>
    </c-col>
  </c-row>

  <c-row class="justify-content-center">
    <c-col [md]="10">
      <c-card>
        <c-card-body class="p-4">
          <c-row class="mb-4">
            <c-col [md]="12">
              <h5>情報</h5>
            </c-col>
            <c-col [md]="12" class="d-flex justify-content-between">
              <c-col>
                <p>ユーザー名</p>
              </c-col>
              <c-col class="d-flex justify-content-end">
                <div *ngIf="showUsername">
                  <div class="d-flex justify-content-end">
                    <p *ngIf="user && user.displayName">{{ user.displayName }}</p>
                  </div>
                  <button cButton class="btn-change-username" (click)="changeState()">ユーザー名</button>
                </div>

                <div *ngIf="showFormChangeUsername">
                  <form cForm
                        [formGroup]="formChangeUsername"
                        (ngSubmit)="changeUsername()"
                        novalidate
                        class="needs-validate"
                  >
                    <c-col>
                      <label cLabel for="username">ユーザー名</label>
                      <input
                        id="username"
                        cFormControl
                        formControlName="username"
                        type="text"
                        placeholder="{{ (user && user.displayName) ? user.displayName : '' }}"
                      >
                    </c-col>
                    <c-col *ngIf="submitted && attribute['username'].invalid" class="invalid-feedback">
                      <c-col *ngIf="attribute['username'].errors && attribute['username'].errors['required']">{{formErrors.username.required}}</c-col>
                      <c-col *ngIf="attribute['username'].errors && attribute['username'].errors['maxLength']">{{formErrors.username.maxLength}}</c-col>
                    </c-col>
                    <c-col class="mt-2 d-flex justify-content-end">
                      <button cButton class="me-2 btn-cancel" (click)="changeState()">キャンセル</button>
                      <button cButton type="submit" class="btn-change-username">保存</button>
                    </c-col>

                  </form>
                </div>

              </c-col>
            </c-col>
          </c-row>

          <c-row class="mb-4">
            <c-col [md]="12">
              <h5>サポート</h5>
            </c-col>
            <c-col [md]="12" class="d-flex">
              <p>noreply@pacenotes.io</p><p>までメールで問い合わせください。</p>
            </c-col>
          </c-row>

          <c-row>
            <c-col [md]="12">
              <h5>アカウント</h5>
            </c-col>
            <c-col [md]="12" class="d-flex justify-content-between align-items-center mb-3">
              <p class="mb-0">メールアドレス</p>
              <p class="mb-0">{{ currentUser?.email }}</p>
            </c-col>
            <c-col *ngIf="checkPass" [md]="12" class="d-flex justify-content-between mb-3">
              <p class="mb-0">パスワード</p>
              <a href="#/update-password" class="btn-link text-orange">パスワード変更</a>
            </c-col>
            <c-col [md]="12" class="d-flex mb-3">
              <label for="logout">
                <img cImg src="assets/svg/logout.svg" alt="logout" />
              </label>
              <button class="mb-0 ms-3 btn-logout text-orange" (click)="logout()" id="logout">ログアウト</button>
            </c-col>
            <c-col [md]="12" class="mb-3">
              <button [cModalToggle]="modalDeleteAccount.id" cButton class="btn-delete-account">
                <img cImg src="assets/svg/trash.svg" alt="delete account" />
                <span class="ms-3">アカウントの削除</span>
              </button>

              <c-modal #modalDeleteAccount backdrop="static" alignment="center" id="modalDeleteAccount">
                <c-modal-header>
                  <h4 cModalTitle>アカウントの削除</h4>
                  <button [cModalToggle]="modalDeleteAccount.id" cButtonClose></button>
                </c-modal-header>
                <c-modal-body>アカウントを削除してよろしいでしょうか？</c-modal-body>
                <c-modal-footer>
                  <button [cModalToggle]="modalDeleteAccount.id" cButton color="secondary">
                    キャンセル
                  </button>
                  <button cButton color="primary" (click)="deleteAccount()">はい</button>
                </c-modal-footer>
              </c-modal>
            </c-col>
            <c-col [md]="12" class="d-flex justify-content-around ">
              <c-col [md]="6" class="">
                <a href="#/purchase">
                  <button cButton class="btn-account-orange">購入</button>
                </a>
              </c-col>
              <c-col [md]="6" class="d-flex align-items-sm-start justify-content-end ">
                <a href="#/payment-method">
                  <button cButton class="btn-account-orange">クレジットカード管理</button>
                </a>
              </c-col>
            </c-col>
          </c-row>
        </c-card-body>
      </c-card>
    </c-col>
  </c-row>
</c-container>
