import { Component } from '@angular/core';
import {AlertService, UserService, ValidationFormsService} from "@app/services";
import {Router} from "@angular/router";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  providers: [ValidationFormsService],
})
export class RegisterComponent {
  formRegister!: UntypedFormGroup;
  submitted = false;
  formErrors: any;

  constructor(
    private userService: UserService,
    private router: Router,
    public vf: ValidationFormsService,
    private fb: UntypedFormBuilder,
    private alertService: AlertService,
  ) {
    this.ngOnInit();
  }

  ngOnInit(): void {
    this.formRegister = this.fb.group(
      {
        email: ['', [Validators.required, Validators.pattern('^(([^<>()[\\]\\\\.,;:\\s@"]+(\\.[^<>()[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$')]],
        password: ['', [Validators.required, Validators.pattern("^(?=.*[A-Z])(?=.*[a-z])(?=.*[\\d])([A-Za-z\\d]{8,32})$")]],
      }
    )

    this.formErrors = this.vf.errorMessages;
  }

  get attribute() {
    return this.formRegister.controls;
  }

  onValidate() {
    this.submitted = true;

    return this.formRegister.status === "VALID";
  }

  register() {
    this.alertService.clear();

    if (this.onValidate()) {
      this.userService.register(this.formRegister.value.email, this.formRegister.value.password).then(res => {
        this.router.navigate(['login']).then();
      })
        .catch(error => {
          this.alertService.error('このメールアドレスは既に登録されています。');
          console.log('Something is wrong:', error.message);
        });
    }
  }
}
